.items-preview-device {
    --device-padding: 1rem;
    --device-width: 293px;
    --device-height: 600px;
    --device-frame-width: 10px;
    padding: var(--device-padding);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.items-preview-device figure {
    margin: 0;
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-box-shadow: 0 2px 8px rgba(2, 8, 20, .1), 0 8px 16px rgba(2, 8, 20, .08);
    box-shadow: 0 2px 8px rgba(2, 8, 20, .1), 0 8px 16px rgba(2, 8, 20, .08);
    width: var(--device-width);
    height: var(--device-height);
    overflow: hidden;
    position: relative;
    z-index: 1;
    background-image: url('../img/iphone-device-skin.png');
    border-radius: 45px;
}

.items-preview-device figure:after {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 2px;
    bottom: 15px;
    content: '';
    height: 3px;
    width: 35%;
    z-index: 1;
    display: block;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.items-preview-device figure iframe {
    position: absolute;
    top: calc(var(--device-frame-width) + 62px); 
    left: 0;
    margin: var(--device-frame-width);
    margin-top: 0;
    width: calc(100% - var(--device-frame-width) * 2);
    height: calc(100% - var(--device-frame-width) * 2 - 62px);
    border: none;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
    z-index: 1;
    border-radius: 0 0 32px 32px;
    background-color: rgba(0, 0, 0, .02);
    -webkit-box-shadow: 10px 10px 20px rgba(0, 0, 0, .1);
    box-shadow: 10px 10px 20px rgba(0, 0, 0, .1);
}


.items-preview-device .items-preview-device__ios-notch {
    display: block;
    fill: #090a0d;
    top:  var(--device-frame-width);
    width: 140px;
    z-index: 2;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.items-preview-device .topbar {
    position: absolute;
    left:var(--device-frame-width);
    top: var(--device-frame-width);
    width: calc(100% - var(--device-frame-width) * 2);
    background: #666;
    color: #fff;
    border-radius: 32px 32px 0 0;
}

.items-preview-device .topbar .icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 calc(var(--device-frame-width) * 2);
    height: 30px;
    border-radius: 32px 32px 0 0;
}

.items-preview-device .topbar .icons-left {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: .7rem;
}

.items-preview-device .topbar .icons-right {
    display: flex;
    align-items: center;
    gap: 10px;
}

.items-preview-device .topbar .address {
    height: 32px;
    padding: 0 .35rem;
    padding-bottom: .35rem;
}

.items-preview-device .topbar input {
    font-size: .9rem;
    max-height: 100%;
    padding: .25rem .35rem;
    border-radius: 4px;
    background: #e7e7e7;
    color: #222;
}