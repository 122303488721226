users .pwrapper {
    width: 85vw;
    margin: auto;
}

users .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    height: 40vh;
    width: 100%;
    opacity: 0.5;
    font-size: 1.2rem;
}

users user .user {
    width: 45%;
}

@media only screen and (max-width: 768px) {

    users .pwrapper {
        width: initial;
    }

    users user .user {
        margin: .5rem;
        padding: .75rem;
        width: calc(100vw - 3rem);
    }
    
}