user h5 {
    margin-top: .2rem;
    margin-bottom: .3rem;
}

user .user .avatar {
    width: 5rem;
    height: 5rem;
    line-height: 5rem;
}

user .user {
    display: flex;
    gap: 1rem;
    position: relative;
    margin: .75rem;
    background: #fff;
    border-radius: .5rem;
    padding: 1rem;
    cursor: default;
}

user .user .bio {
    margin: .5rem 0;
    font-size: .95rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

user .user .contact {
    display: flex;
    flex-wrap: wrap;
    gap: .8rem;
    font-size: 1.2rem;
}

@media only screen and (max-width: 768px) {

    users user .user .avatar {
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
    }

}