profile .pwrapper {
    display: flex;
    padding: 2rem 4rem;
}

profile .left-container {
    width: 700px;
    margin: 0 auto;
}

profile .right-container {
    padding-left: 1rem;
}

profile .timeline-container {
    padding: 1rem;
    padding-left: 80px;
    overflow: auto;
    height: calc(100vh - var(--header-height) - 13rem);
}

profile .gd-link-container .gd-link {
    border-color: var(--primary-color);
    background: transparent;
    padding: .25rem .35rem;
    padding-right: 5rem;
}

profile .bio-input {
    padding-right: 4rem;
}

profile .social-input {
    padding-left: 2.25rem;
    padding-right: 4rem;
    border-radius: 1rem;
    pointer-events: none;
}

profile .input-addon-right:not(.fa-spin) {
    top: 0;
    height: 100%;
    min-width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

profile .edit {
    display: none !important;
}

profile [editing="true"] .edit {
    display: none;
}

profile [editing="false"]:hover .edit {
    display: flex !important;
}

profile [loading="true"]:hover .edit {
    display: none !important;
}

profile [editing="false"] .ok,  profile [editing="false"] .nok {
    display: none;
}

profile [editing="true"] .ok,  profile [editing="true"] .nok {
    display: flex;
    cursor: pointer;
}

profile [editing="true"] .social-input {
    pointer-events: initial;
}

profile [editing="true"] .bio-input {
    padding-right: 4rem;
    pointer-events: initial;
}

profile [editing="true"] .nok {
    right: 2.25rem;
}

profile .p-relative {
    margin-bottom: .25rem;
}

profile .user-interests {
    min-height: 28px;
}

profile .interest {
    padding: .5rem;
    display: flex;
    align-items: center;
    width: 48%;
}

profile .interests .popup {
    width: 600px;
}

profile .interests .popup .content {
    max-height: 50vh;
    margin-bottom: 1rem;
}

profile .interests label {
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    profile .pwrapper {
        padding: 1rem;
        flex-wrap: wrap;
    }

    profile .left-container {
        width: initial;
        flex-grow: 1;
    }

    profile .right-container {
        margin-top: 2rem;
        padding-left: 0;
    }

    profile .timeline-container {
        height: initial;
    }

    profile .edit {
        display: flex !important;
    }

    profile [editing="true"] .edit {
        display: none !important;
    }
}