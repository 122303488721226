@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital@0;1&display=swap');
@import url('https://use.fontawesome.com/releases/v5.14.0/css/all.css');

:root {
    --header-height: 80px;
    --background-color: #e8eef7;
    --text-color: #444;
    --text-discrete-color: #777;
    --menu-color: #1479FF;
    --primary-color: #1479FF36;
    --selected-color: #147aff4d;
}

html {
    overflow-y: overlay;
}

* {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

body {
    background: var(--background-color);
    color: var(--text-color);
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    margin-bottom: .5rem;
}

a {
    color: var(--text-color);
    text-decoration: none;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--selected-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888;
}

/* ========================= */
/* Components                */
/* ========================= */

/* Buttons */
button {
    background: var(--primary-color);
    border-radius: .25rem;
    padding: .5rem .7rem;
    border: 0;
    cursor: pointer;
    font-size: 1rem;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
}

button.outline {
    background: transparent;
    border: 1px solid var(--primary-color);
    box-shadow: none;
}

[loading] .fa-circle-notch {
    display: none;
}

[loading="true"] .fa-circle-notch {
    display: inline-block;
}

/* Inputs */
input {
    font-size: 1rem;
    border: 1px solid var(--background-color);
    background: #fff;
    border-radius: .25rem;
    padding: .5rem .7rem;
}

input:focus {
    outline: none;
    border-color: var(--selected-color);
    box-shadow: 0 0 10px var(--selected-color);
}

input:-webkit-autofill {
    box-shadow: 0 0 0 50px #fff inset;
    /* Change the color to your own background color */
    -webkit-box-shadow: 0 0 0 50px #fff inset;
    /* Change the color to your own background color */
}

input:-webkit-autofill:focus {
    background: #fff;
}

input:-webkit-autofill:active {
    background: #fff;

}

textarea {
    font-size: 1rem;
    border: 1px solid var(--background-color);
    background: #fff;
    border-radius: .25rem;
    padding: .5rem .7rem;
    resize: none;
}

textarea:focus {
    outline: none;
    border-color: var(--selected-color);
    box-shadow: 0 0 10px var(--selected-color);
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b2bcca;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b2bcca;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #b2bcca;
}

.input-addon-left {
    position: absolute;
    left: .75rem;
    top: .75rem;
}

.input-addon-right {
    position: absolute;
    right: .75rem;
    top: .75rem;
}

/* Checkbox */
.checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid var(--background-color);
    background: #fff;
    border-radius: .25rem;
}

.checkbox:hover input~.checkmark {
    outline: none;
    border-color: var(--selected-color);
    box-shadow: 0 0 10px var(--selected-color);
}

.checkbox input:checked~.checkmark {
    background-color: var(--primary-color);
}

.checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox .checkmark:after {
    border-radius: 2px;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox input:checked~.checkmark:after {
    display: block;
}

/* Dropdown */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown .dropdown-trigger {
    cursor: pointer;
}

.dropdown .dropdown-trigger.fa {
    cursor: pointer;
    color: #147aff;
    padding: 5px;
    border-radius: .25rem;
}

.dropdown .dropdown-trigger.fa:hover {
    background: var(--background-color);
}

.dropdown .dropdown-content {
    display: none;
    position: absolute;
    left: -175px;
    background-color: #fff;
    min-width: 225px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: .5rem;
    z-index: 1;
}

.dropdown .dropdown-content .dropdown-item {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

.dropdown .dropdown-content .dropdown-item:first-of-type {
    border-radius: .5rem .5rem 0 0;
}

.dropdown .dropdown-content .dropdown-item:last-of-type {
    border-radius: 0 0 .5rem .5rem;
}

.dropdown .dropdown-content .dropdown-item:hover {
    background-color: var(--background-color);
}

.dropdown.open .dropdown-content {
    display: block;
}

.dropdown.open .dropdown-trigger.fa {
    background: var(--background-color);
}

/* ========================= */
/* Utils                    */
/* ========================= */
.h-100 {
    height: 100%;
}

.w-100 {
    width: 100%;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-discrete {
    color: var(--text-discrete-color);
    font-size: .95rem;
}

.d-none {
    display: none;
}

.d-flex {
    display: flex;
}

.d-flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.d-flex-columns {
    display: flex;
    flex-direction: column;
}

.flex-jc-center {
    justify-content: center;
}

.flex-ai-center {
    align-items: center;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.p-relative {
    position: relative;
}

.fluid input {
    width: 100%;
}

.fluid textarea {
    width: 100%;
}

.fluid button {
    width: 100%;
}

.row {
    margin: 0.7rem 0;
}

/* ========================= */
/* Layout                    */
/* ========================= */
.wrapper {
    height: 100vh;
    display: flex;
}

.container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.header {
    position: fixed;
    width: 100vw;
    background: var(--background-color);
    height: var(--header-height);
    z-index: 100;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 20px 30px -25px var(--selected-color);
}

.header .logo a {
    display: flex;
}

.header .logo img {
    height: 48px;
}

.main {
    margin-top: var(--header-height);
    padding: 1rem;
    flex-grow: 1;
}

.avatar {
    line-height: 3rem;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    overflow: hidden;
    background: #fff;
    border: 1px solid var(--menu-color);
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar-menu {
    visibility: hidden;
}

.avatar-menu .username {
    font-size: .9rem;
    padding: .5rem;
}

.main-menu {
    display: none;
}

@media only screen and (max-width: 768px) {
    :root {
        --header-height: 60px;
    }

    .header {
        padding: 0 1rem;
    }

    .header .logo img {
        height: 36px;
    }

    .avatar-menu .avatar {
        line-height: 2.5rem;
        height: 2.5rem;
        width: 2.5rem;
    }
}

/* ========================= */
/* Menu                     */
/* ========================= */
.menu-container {
    background: #fff;
    border-radius: 2rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu-item {
    background: var(--background-color);
    border-radius: 30%;
    line-height: 3rem;
    width: 3rem;
    margin: 1rem;
    text-align: center;
    border: 2px;
    color: #147aff;
    cursor: pointer;
}

.menu-item.selected {
    color: #fff;
    background-color: var(--menu-color);
    box-shadow: 0px 9px 24px var(--selected-color);
}

.main-search-wrapper {
    margin: auto;
}

.main-search-wrapper[search] .fa-times {
    cursor: pointer;
    display: none;
}

.main-search-wrapper[search="true"] .fa-times {
    display: initial;
}


.main-search-input {
    width: 50vw;
    padding-left: 2.25rem;
    border-radius: 1rem;
}

@media only screen and (max-width: 768px) {
    .main-search-wrapper {
        margin: 0 .5rem;
    }

    .main-search-input {
        width: 100%;
    }
}

/* ========================= */
/* Overlay Popup             */
/* ========================= */
.block {
    position: fixed;
    z-index: 200;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 1.25rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: none;
}

.overlay {
    position: fixed;
    z-index: 200;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
    display: none;
    /* set to flex when visible. */
}

.overlay .popup {
    margin: 150px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
}

.overlay .popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
}

.overlay .popup .close {
    position: absolute;
    top: 10px;
    right: 20px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    z-index: 200;
}

.overlay .popup .close:hover {
    color: #147aff;
}

.overlay .popup .content {
    max-height: 30%;
    overflow: auto;
}

.overlay .popup .message {
    width: initial;
    margin-bottom: 1rem;
}

.overlay .popup .footer {
    text-align: right;
}

@media only screen and (max-width: 768px) {
    .overlay .popup {
        margin-top: 75px;
        width: 90% !important;
    }
}

/* ========================= */
/* Toast                     */
/* ========================= */
.toast {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: #222;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: .6rem;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    border-radius: .25rem;
}

.toast.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

/* ========================= */
/* Common                     */
/* ========================= */
@keyframes pulse {
    0% {
        /*transform: scale(0.95);*/
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        /*transform: scale(1);*/
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        /*transform: scale(0.95);*/
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.user-interest-tag {
    background: lightblue;
    border-radius: .25rem;
    display: inline-block;
    padding: 2px 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: .9rem;
}

.profile-photo {
    position: relative;
    height: 130px;
    width: 130px;
}

.profile-photo .img-container {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.profile-photo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.profile-photo input {
    display: none;
}

.profile-photo label {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    color: #fff;
    padding: .6rem;
    background-color: #000000aa;
    cursor: pointer;
}

.profile-photo-zoom {
    padding: 0;
    margin: 0;
}