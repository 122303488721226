privacy .pwrapper {
    width: 85vw;
    margin: auto;
}

privacy h2, privacy h3 {
    margin-top: 2rem;
}

terms .pwrapper {
    width: 85vw;
    margin: auto;
}

terms h2, terms h3 {
    margin-top: 2rem;
}