/* ========================= */
/* Home Page             */
/* ========================= */
home p {
    text-align: center;
    margin: .5rem 0;
}

home .steps {
    padding-top: 4rem;
    padding-bottom: 3rem;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

home .step {
    border-radius: 0.5rem;
    padding: .5rem;
    margin-bottom: 1rem;
    text-align: center;
    width: 25vw;
    min-width: 250px;
    position: relative;
}

home .step img {
    width: 100%;
    min-width: 300px;
    max-height: 240px;
    margin-bottom: 2rem;
}

home .circle {
    position: absolute;
    top: -2rem;
    left: -2rem;
    display: inline-block;
    width: 4rem;
    height: 4rem;
    font-size: 1.25rem;
    line-height: 4rem;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    background: #2f2e41;
    box-shadow: 0px -9px 24px var(--selected-color);
}

home .start {
    animation: pulse 2s infinite;
}