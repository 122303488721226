auth > div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 100%;
}

auth div.fluid {
    padding: 1rem;
    width: 450px;
    background: #fff;
    border-radius: .5rem;
    box-shadow: 0px 9px 24px var(--selected-color);
}

auth h3 {
    margin-bottom: 2rem;
}

auth button {
    margin-bottom: .5rem;
}

auth .login .fbloginbtn {
    background-color: #3B5998;
    color: white;
    text-align: left;
}

auth .login .gologinbtn {
    background-color: #dd4b39;
    color: white;
    text-align: left;
}

auth .login .twloginbtn {
    background-color: #55ACEE;
    color: white;
    text-align: left;
}

auth .wait .fa-spin {
    font-size: 2rem;
}

/* User name */
auth .username-row {
    display: flex;
}

auth .username-row > div:first-of-type {
    display: flex;
    align-items: center;
    padding: .5rem .7rem;
    padding-right: .2rem;
    border: 1px solid var(--background-color);
    background: var(--background-color);
    border-radius: .25rem 0 0 .25rem;
}

auth .username-row > div:nth-of-type(2) {
    flex-grow: 1;
}

auth .username-row  input {
    border-radius:  0 .25rem .25rem 0;
    padding-left: .2rem;
}

auth .username-info {
    visibility: hidden;
}

auth .username-info .available-msg {
    color: green;
    display: none;
}

auth .username-info.available {
    visibility: visible;
}

auth .username-info.available .available-msg {
    display: initial;
}

auth .username-info .taken-msg {
    display: none;
}

auth .username-info.taken {
    visibility: visible;
}

auth .username-info.taken .taken-msg {
    color: red;
    display: initial;
}

auth .info {
 font-size: .9rem;
}

auth .error {
    margin-top: .5rem;
    text-align: center;
    color: red;
    visibility: hidden;
}

auth .fa-eye {
    cursor: pointer;
}

auth .fa-eye:hover {
    color: var(--menu-color);
}


@media only screen and (max-width: 768px) {

    auth div.fluid {
        width: 90vw;
    }
    
}

