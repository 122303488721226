items {
    --link-image-size: 125px;
    --link-background: #fff;
    --link-color: "default";
    --link-padding: .75rem;
    --link-border-radius: .5rem;
    --link-image-border-radius: .35rem;
    --link-tag-background: #111;
    --link-tag-color: #fff;
}

items h4 {
    margin: 0;
}

items .pwrapper {
    width: 85vw;
    margin: auto;
}

/* Edit buttons. */
items .edit-buttons {
    display: none;
    margin-bottom: .5rem;
    text-align: center;
}

items .edit-buttons button {
    padding: .4rem .5rem;
}

items .edit-buttons .preview-button {
    background: #A3CBB4;
}

items .edit-buttons .add-link-button {
    animation: pulse 2s infinite;
}

items .edit-buttons .clear-search-button {
    display: none;
}

items .edit-buttons.insearch .clear-search-button {
    display: initial;
}

items .edit-buttons.insearch button:not(.clear-search-button) {
    display: none;
}

/* Items Container. */
items .items-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4rem;
    /*grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
    justify-content: center;
    */
    height: fit-content;
    flex-grow: 1;

}

items .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    height: 40vh;
    width: 100%;
    opacity: 0.5;
    font-size: 1.2rem;
}

/* Title items. */
items .title {
    width: 100%;
    margin: .75rem;
    background: var(--link-background);
    color: var(--link-color);
    border-radius: var(--link-border-radius);
    padding: var(--link-padding);
    display: flex;
    justify-content: space-between;
}

items .title .dropdown {
    display: none;
}

items .title .dropdown .dropdown-content  {
    left: -199px;
}

/* Link items. */
items .item {
    position: relative;
    margin: .75rem;
    background: var(--link-background);
    border-radius: var(--link-border-radius);
    padding: var(--link-padding);
    width: 30%;
    cursor: default;
}

items .item a {
    color: var(--link-color);
}

items .item .grip {
    position: absolute;
    left: 1rem;
    top: 1rem;
    display: none;
}

items .item .link  {
    display: flex;
    align-items: center;
    gap: .5rem;
    height: 100%;
}

items .item .dropdown {
    display: none;
}

items .item .name {
    display: flex;
    align-items: center;
}

items .item .name a {
    flex-grow: 1;
    min-width: 0;
}

items .item .name h4 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

items .item img {
    border-radius: var(--link-image-border-radius);
    width: var(--link-image-size);
    max-height: var(--link-image-size);
    object-fit: contain;
}

items .item .text-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    min-width: 0;
}

items .item .desc {
    margin: .5rem 0;
    flex-grow: 1;
    font-size: .95rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

items .tags {
    overflow: hidden;
}

items .tag {
    background: var(--link-tag-background);
    color: var(--link-tag-color);
    border-radius: .25rem;
    display: inline-block;
    padding: 2px 5px;
    margin: 1px;
    margin-right: 5px;
    font-size: .9rem;
    white-space: nowrap;
}

items .item .dropdown .dropdown-content  {
    left: -198px;
}

items .item:first-of-type .dropdown .dropdown-content .moveup  {
    display: none;
}

items .item:last-of-type .dropdown .dropdown-content .movedown  {
    display: none;
}

.firebase-emulator-warning {
    display: none;
}

items .preview-container {
    display: none;
}

/* New item dialog. */
items .newitem .popup {
    width: 600px;
}

items .newitem .img-desc-tags {
    display: flex;
    justify-content: center;
}

items .newitem .images {
    position: relative;
    display: flex;
    margin-right: 0.7rem;
    margin-bottom: 0.7rem;
}

items .newitem .images .indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    opacity: .8;
    flex-direction: column;
    display: none;
}

items .newitem .images .indicator .fa {
    font-size: 2rem;
}

items .newitem .images.scraping .indicator {
    display: flex;
}

items .newitem .images .dropdown {
    position: absolute;
    left: .3rem;
    top: .3rem;
    /*display: none;*/
}

items .newitem .images .dropdown-content {
    left: 0;
}

items .newitem .tags {
    border: 1px solid var(--background-color);
    background: #fff;
    border-radius: .25rem;
    padding: .3rem .7rem;
    min-height: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

items .newitem .tags.focus {
    border-color: var(--selected-color);
    box-shadow: 0 0 10px var(--selected-color);
}

items .newitem .tags .tag {
    padding: 1px 5px;
}

items .newitem .tags input {
    border: none;
    padding: 0;
    outline: none;
    box-shadow: none;
    appearance: none;
    width: 7rem;
    /*display: none;*/
}

/* Pick image dialog. */
items .pickimage {
    background: rgba(0, 0, 0, 0.2);
}

items .pickimage .popup {
    width: 500px;
}

items .pickimage .popup .content {
    padding: 1rem;
    margin-bottom: 1rem;
    max-height: 50vh;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--link-image-size), var(--link-image-size)));
    grid-gap: .5rem;
    justify-content: center;
}

/* User detail & contacts */
items .userdetail  {
    display: none;
}

items .userdetail .avatar {
    margin: 0 auto;
    width: 4rem;
    height: 4rem;
}

items .userdetail .name {
    text-align: center;
    font-weight: bold;
}

items .userdetail .contact {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: .6rem;
    font-size: 1.2rem;
    padding-bottom: 1rem;
}

items .user-contact {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: .7rem;
    background: var(--background-color);
    box-shadow: 0px 20px 30px 7px var(--selected-color);
    display: none;
}

items .user-contact .fab {
    font-size: 1.35rem;
    cursor: pointer;
}

items .image {
    cursor: pointer;
    padding: .5rem;
    width: var(--link-image-size);
    height: var(--link-image-size);
    object-fit: contain;
    border-radius: .25rem;
    border: 1px solid var(--background-color);
}

items .image.selected {
    outline: none;
    border-color: var(--selected-color);
    box-shadow: 0 0 10px var(--selected-color);
}

items .image:hover {
    outline: none;
    border-color: var(--selected-color);
    box-shadow: 0 0 10px var(--selected-color);
}

items .image-info {
    position: absolute;
    background: #00000066;
    color: #fff;
    border-radius: .15rem;
    bottom: 5px;
    right: 5px;
    font-size: .8rem;
    padding: 2px 4px;
}


/* EDIT MODE: Enable buttons. */
items.edit .edit-buttons {
    display: block;
}

items.edit .item {
    cursor: move;
}

items.edit .item .link  {
    cursor: inherit;
}

items.edit .item .dropdown {
    display: initial;
}

items.edit .title .dropdown {
    display: initial;
}

items.edit .item:hover .grip {
    display: block;
}


/* Drag. */
items .chosen {
    opacity: 1 !important;
}

items .ghost {
    opacity: .5 !important;
}


@media only screen and (max-width: 768px) {
    items {
        --link-image-size: 60px;
    }

    items .pwrapper {
        width: initial;
    }

    items .items-container {
        justify-content: center;
    }

    items .preview-container {
        display: none !important;
    }

    items .title {
        margin: .5rem;
        padding: .75rem;
    }

    items .item {
        margin: .5rem;
        width: calc(100vw - 3rem);
    }

    items .item .desc {
        margin: .25rem 0;
        -webkit-line-clamp: 2;
    }

    items .pickimage .content {
        padding: 0;
    }

    items .newitem .img-desc-tags {
        flex-wrap: wrap;
    }

}

